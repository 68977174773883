import { ChangeEvent, forwardRef } from 'react';
import classNames from 'classnames';

import InputText, { Props as InputProps } from '@templates/InputText';
import defer from 'utils/defer';

import classes from './InputSearch.module.scss';

export type Props = Pick<InputProps, 'id' | 'value' | 'placeholder' | 'className' | 'endAdornment' | 'clearable'> & {
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
};

const InputSearch = forwardRef<HTMLInputElement, Props>(
  ({ id, placeholder, value, className, endAdornment, clearable, onChange, onFocus, onBlur }, ref): JSX.Element => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    return (
      <InputText
        ref={ref}
        id={id}
        dataEid="input-search"
        className={classNames([classes.input, className])}
        name="name"
        variant="raw"
        value={value}
        placeholder={placeholder}
        endAdornment={endAdornment}
        clearable={clearable}
        onChange={handleInputChange}
        onClick={() => onFocus?.()}
        onBlur={() => {
          if (typeof onBlur === 'function') {
            defer(onBlur);
          }
        }}
        withPlaceholderOnFocus
        unmask
      />
    );
  }
);

export default InputSearch;
