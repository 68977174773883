/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['sm', 'md', 'lg'] }] */
import { ChangeEvent, ChangeEventHandler, forwardRef, RefObject } from 'react';
import classnames from 'classnames';

import CrossIcon from 'assets/icons/cross.svg';
import Typography from '@components/atoms/Typography';
import createStyleVariables from 'utils/createStyleVariables';

import classes from './InputText.module.scss';
import { Props } from './InputText.types';

const InputText = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      id,
      name,
      autocomplete,
      value,
      type = 'text',
      variant = 'default',
      placeholder,
      label,
      isFloatingPlaceholder = false,
      withPlaceholderOnFocus = false,
      suffix,
      suffixPosition = 'stretch',
      isMultiline = false,
      size = 'sm',
      required = false,
      unmask,
      endAdornment,
      className,
      touched,
      error,
      disabled,
      readOnly,
      dataEid,
      maxLength,
      clearable,
      withoutErrorMessage,
      onClick,
      onChange,
      onBlur,
      onInput,
    },
    ref
  ): JSX.Element => {
    const isWrong = touched && error;

    const baseProps = {
      id,
      name,
      value,
      disabled,
      readOnly,
      maxLength,
      autoComplete: autocomplete || 'off',
      placeholder: isFloatingPlaceholder ? '' : `${placeholder}${required ? '*' : ''}`,
      'data-eid': dataEid,
      'data-sentry-unmask': unmask,
      onBlur,
      onInput,
    };

    return (
      <div style={createStyleVariables({ suffixPosition })} className={className} data-error={!!isWrong}>
        {!!label && (
          <Typography className={classes.label} variant="body2" renderAs="div">
            {label}
          </Typography>
        )}
        <div
          className={classnames(classes.inputBorder, {
            [classes.disabled]: disabled,
            [classes.inputRaw]: variant === 'raw',
            [classes.inputFilled]: variant === 'filled',
            [classes.hasError]: isWrong,
            [classes.filled]: value && value.length > 0,
          })}
        >
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className={classnames(classes.inputWrapper, classes[size], {
              [classes.inputWrapperWithoutAdortment]: !endAdornment,
              [classes.isMultiline]: isMultiline,
            })}
          >
            <div className={classes.inputContent}>
              {isMultiline ? (
                <textarea
                  ref={ref as RefObject<HTMLTextAreaElement>}
                  className={classnames(classes.input, classes.isMultiline, {
                    [classes.focusPlaceholder]: withPlaceholderOnFocus,
                  })}
                  rows={5}
                  onChange={onChange as ChangeEventHandler<HTMLTextAreaElement>}
                  {...baseProps}
                />
              ) : (
                <input
                  ref={ref as RefObject<HTMLInputElement>}
                  type={type}
                  className={classnames(classes.input, {
                    [classes.inputRaw]: variant === 'raw',
                    [classes.readOnly]: readOnly,
                    [classes.focusPlaceholder]: withPlaceholderOnFocus,
                  })}
                  onClick={onClick}
                  onChange={onChange as ChangeEventHandler<HTMLInputElement>}
                  {...baseProps}
                />
              )}
              {isFloatingPlaceholder && placeholder && (
                <div
                  className={classnames(classes.floatingPlaceholder, {
                    [classes.floatingPlaceholderMoved]: value !== '',
                  })}
                >
                  {required ? (
                    <>
                      {placeholder}
                      <span className={classes.required}>*</span>
                    </>
                  ) : (
                    placeholder
                  )}
                </div>
              )}
            </div>
            {suffix && <div className={classes.suffix}>{suffix}</div>}
          </label>
          {clearable && value && (
            <CrossIcon
              className={classes.clearIcon}
              onClick={() =>
                onChange?.({ target: { value: '' } } as ChangeEvent<HTMLTextAreaElement> &
                  ChangeEvent<HTMLInputElement>)
              }
            />
          )}
          {endAdornment}
        </div>
        {isWrong && !withoutErrorMessage && (
          <div
            className={classnames(classes.message, {
              [classes.errorMessage]: isWrong,
            })}
          >
            {error}
          </div>
        )}
      </div>
    );
  }
);

export default InputText;
