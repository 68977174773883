import { useState, MouseEvent, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';

import config from 'config';
import { Classes } from 'types/Classes';
import { smallSearchRoute } from 'apiRoutes/smallSearch';
import pruneObject from 'utils/pruneObject';
import { getSearchPayload } from 'utils/analytics/payload/search';
import { useDebounce } from 'hooks/useDebounce';
import useTrack from 'hooks/useTrack';
import useFetch from 'hooks/useFetch';

import SearchBox from './SearchBox';

const { routes, defaultMinPhraseLength } = config;

type Props = {
  classes?: Classes<'root'>;
  placeholder?: string;
  searchName?: string;
  withAutofocus?: boolean;
  onSearch?: (value: string) => void;
  onFocus?: (value: boolean) => void;
  onItemClick?: (e: MouseEvent<HTMLAnchorElement>, id: number, categoryId: number) => void;
  onSubmit?: (phrase: string) => void;
};

const SearchBoxContainer = ({
  classes: customClasses,
  placeholder,
  searchName = '',
  withAutofocus,
  onSearch,
  onFocus,
  onItemClick,
  onSubmit,
}: Props): JSX.Element => {
  const { trackLink } = useTrack();
  const [searchValue, setSearchValue] = useState(searchName);
  const router = useRouter();
  const [refetch, { data, loading }] = useFetch(smallSearchRoute);

  const trimmedSearchValue = useMemo(() => searchValue.trim(), [searchValue]);

  const handleChange = (value: string) => {
    setSearchValue((currentValue) => (currentValue !== value ? value : currentValue));
  };

  const handleSearch = (name: string) => {
    trackLink(
      'e-commerce',
      'search',
      getSearchPayload('', name)
    )(() => {
      router.push({ pathname: routes.search.href, query: pruneObject({ name }) });
    });
  };

  useDebounce(() => {
    if (
      (trimmedSearchValue && trimmedSearchValue.length >= defaultMinPhraseLength) ||
      (onSearch && !trimmedSearchValue)
    ) {
      if (onSearch) {
        onSearch(trimmedSearchValue);
      } else {
        refetch({ name: encodeURIComponent(trimmedSearchValue) });
      }
    }
  }, [trimmedSearchValue]);

  useEffect(() => {
    setSearchValue(searchName);
  }, [searchName]);

  return (
    <SearchBox
      classes={customClasses}
      placeholder={placeholder}
      results={data?.categories}
      meta={data?.meta}
      value={searchValue}
      loading={loading}
      withAutofocus={withAutofocus}
      onFocus={onFocus}
      onChange={handleChange}
      onSearch={onSubmit || handleSearch}
      onItemClick={onItemClick}
    />
  );
};

export default SearchBoxContainer;
