import { useContext } from 'react';
import Image from '@next/image';

import config from 'config';
import { Classes } from 'types/Classes';
import Link from '@components/atoms/Link';
import { ConfigContext } from '@providers/ConfigProvider';
import { FilesContext } from '@providers/FilesProvider';

const { routes } = config;

type Props = {
  classes?: Classes<'link' | 'image'>;
  withLink?: boolean;
  isAbsolute?: boolean;
  hasPreload?: boolean;
  height?: number;
};

const Logo = ({
  classes = {},
  height,
  withLink = false,
  isAbsolute = false,
  hasPreload = false,
}: Props): JSX.Element => {
  const { appUrl, contact } = useContext(ConfigContext);
  const { getShopImageConfigs } = useContext(FilesContext);
  const [logo] = getShopImageConfigs(['logo']);

  const label = `logo ${contact.appName}`;

  const image = logo ? (
    <Image
      className={classes.image}
      objectFit="contain"
      alt={label}
      resolveConfig={{ ...logo, preset: 'svg' }}
      hasFade={false}
      hasLazyLoad={false}
      hasPreload={hasPreload}
      height={height}
    />
  ) : (
    <></>
  );

  return withLink ? (
    <Link component="span" href={isAbsolute ? appUrl : routes.home.href} className={classes.link} aria-label={label}>
      {image}
    </Link>
  ) : (
    image
  );
};

export default Logo;
